import React, { useState, useEffect } from 'react';

const Timer = ({ onTimeout }) => {
  const [timeLeft, setTimeLeft] = useState(10);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let timer;
    if (isActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 0.1) {
            clearInterval(timer);
            setIsActive(false);
            onTimeout();
            return 0;
          }
          return prevTime - 0.1;
        });
      }, 100);
    } else if (timeLeft === 0) {
      setIsActive(false);
      onTimeout();
    }
    return () => clearInterval(timer);
  }, [timeLeft, isActive, onTimeout]);

  const percentage = (timeLeft / 10) * 100;

  return (
    <div className="w-full bg-gray-200 h-2 mt-4">
      <div 
        className={`h-2 transition-all duration-100 ease-linear ${
          isActive ? 'bg-red-600' : 'bg-gray-400'
        }`}
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};

export default Timer;