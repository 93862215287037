// Import your images
import belgiumFlag from './images/belgium-flag.png';
import austriaFlag from './images/austria-flag.png';
import hungaryFlag from './images/hungary-flag.png';

const questions = [
  {
    question: "Which country does this flag belong to?",
    image: belgiumFlag,
    options: ["Belgium", "Germany", "Lithuania", "Bolivia"],
    correctAnswer: "Belgium"
  },
  {
    question: "Which country does this flag belong to?",
    image: austriaFlag,
    options: ["Austria", "Poland", "Spain", "Slovakia"],
    correctAnswer: "Austria"
  },
  {
    question: "Which country does this flag belong to?",
    image: hungaryFlag,
    options: ["Hungary", "Italy", "Mali", "Romania"],
    correctAnswer: "Hungary"
  },
  {
    question: "Where was the 2016 Olympics held?",
    options: ["Tokyo", "Rio", "Los Angeles", "Sochi"],
    correctAnswer: "Rio"
  }
];
export default questions
