import React from 'react';
import OlympicQuiz from './OlympicQuiz';

function App() {
  return (
    <div className="App">
      <OlympicQuiz />
    </div>
  );
}

export default App;
