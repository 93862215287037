import React, { useState, useEffect } from 'react';
import { AlertDialog, AlertDialogAction, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "./components/ui/alert-dialog";
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./components/ui/card";
import Timer from './Timer';
import questions from './quizQuestions';

const OlympicQuiz = () => {
  const [name, setName] = useState('');
  const [started, setStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isTimerFinished, setIsTimerFinished] = useState(false);
  const [answers, setAnswers] = useState([]);

  
  const nextQuestion = () => {
    if (selectedAnswer === questions[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }
    
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedAnswer(null);
      setIsTimerFinished(false);
    } else {
      setShowScore(true);
      console.log(`Name: ${name}, Score: ${score}`);
    }
  };

  const handleTimeout = () => {
    setIsTimerFinished(true);
    if (!selectedAnswer) {
      nextQuestion();
    }
  };

  const handleAnswer = (answer) => {
    setSelectedAnswer(answer);
    setAnswers([...answers, { question: currentQuestion, answer }]);
  };

  const saveQuizResult = async () => {
    console.log('Attempting to save quiz result:', { name, answers, score });
    try {
      const response = await fetch('/api/saveQuizResult', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          answers,
          score,
        }),
      });
      const data = await response.json();
      console.log('Response from server:', data);
    } catch (error) {
      console.error('Error saving quiz result:', error);
    }
  };

  useEffect(() => {
    if (showScore) {
      saveQuizResult();
    }
  }, [showScore]);

  if (!started) {
    return (
      <Card className="w-[350px]">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">10 Squared Olympic Quiz</CardTitle>
          <CardDescription className="text-center">
            You have 10 Seconds to answer each question
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Input
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </CardContent>
        <CardFooter>
          <Button onClick={() => setStarted(true)} disabled={!name} className="w-full">
            Start Quiz
          </Button>
        </CardFooter>
      </Card>
    );
  }

  if (showScore) {
    return (
      <AlertDialog open={showScore}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Quiz Completed!</AlertDialogTitle>
            <AlertDialogDescription>
              {name}, your final score is: {score} out of {questions.length}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => window.location.reload()}>
              Play Again
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  return (
    <Card className="w-[350px]">
      <CardHeader>
        <CardTitle className="text-lg font-bold">
          Question {currentQuestion + 1} of {questions.length}
        </CardTitle>
        <CardDescription className="mt-4">
          {questions[currentQuestion].question}
        </CardDescription>
      </CardHeader>
      <CardContent>
        {questions[currentQuestion].image && (
          <img 
            src={questions[currentQuestion].image} 
            alt="Question Image" 
            className="w-full h-auto mb-4 max-h-48 object-contain"
          />
        )}
        {questions[currentQuestion].options.map((option, index) => (
          <Button
            key={index}
            onClick={() => handleAnswer(option)}
            className={`w-full mt-2 ${
              selectedAnswer === option 
                ? 'bg-blue-500 hover:bg-blue-500 text-white' 
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
            variant={selectedAnswer === option ? "default" : "outline"}
            disabled={isTimerFinished}
          >
            {option}
          </Button>
        ))}
        <Timer key={currentQuestion} onTimeout={handleTimeout} />
      </CardContent>
      <CardFooter>
        <Button 
          onClick={nextQuestion} 
          className="w-full btn btn-blue"
          disabled={!selectedAnswer && !isTimerFinished}
        >
          Next Question
        </Button>
      </CardFooter>
    </Card>
  );
};

export default OlympicQuiz;